import { createRouter, createWebHistory } from 'vue-router'
import DashboardView from '../views/DashboardView.vue'
import ProfileView from '../views/ProfileView.vue'
import AppLayout from '@/layout/AppLayout.vue';
const routes = [
  {
    path: '/',
    name: 'home',
    component: AppLayout,
    children:[
      {
        path:'/',
        component:DashboardView
      },
      {
        path: '/access',
        name: 'access',
        component: () => import('@/views/access/AccessView.vue')
    },
    {
      path: '/institutions',
      name: 'institutions',
      component: () => import('@/views/institutions/InstitutionsView.vue')
  },
    {
      path: '/catalogo',
      name: 'catalogo',
      component: () => import('@/views/catalogs/AssetCatalogView.vue')
  },
    {
      path: '/estados',
      name: 'estados',
      component: () => import('@/views/statusAssets/AssetsStatusView.vue')
  },
    {
      path: '/empleados',
      name: 'empleados',
      component: () => import('@/views/employees/EmployeesView.vue')
  },
    {
      path: '/accesosinventarios',
      name: 'accesosinventarios',
      component: () => import('@/views/acessInventories/accesInventoriesView.vue')
  },
    {
      path: '/assetinventories',
      name: 'assetinventories',
      component: () => import('@/views/assetInventories/AssetInventories.vue')
  },
    {
      path: '/assetInventoryesp',
      name: '/assetInventoryesp',
      component: () => import('@/views/assetInventories/AssetInventoriesOffice.vue')
  },
    {
      path: '/registerinventory/:idinventory',
      name: 'registerinventory',
      component: () => import('@/views/assetInventories/RegisterInventory.vue')
  },
    {
      path: '/accounting',
      name: 'accounting',
      component: () => import('@/views/accountingAccount/AccountingView.vue')
  },
    {
      path: '/recordadvancement',
      name: 'recordadvancement',
      component: () => import('@/views/reports/AdvancementView.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component:ProfileView
  },
    ]
  },
  
  {
    path: '/login',
    name: 'login',
    //route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/register',
    name: 'register',
    //route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/RegisterView.vue')
  }
  //{
    //path: '/about',
    //name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  //}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
