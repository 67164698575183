<template>
  <router-view/>
</template>
<script setup>
import { onBeforeMount } from 'vue';
import { useRouter } from 'vue-router'; // Asegúrate de importar Vue Router si lo estás utilizando
import { useStore } from 'vuex';
const store = useStore();
const router = useRouter();

onBeforeMount(() => {
  //obteniendo toda la data de localstorege y donde se lamacenara los datos del usuario
  let prefijoApp=  store.getters.getPrefijoApp
  let token= localStorage.getItem(prefijoApp + '_token');
  if (token===null) {
    router.push('/login');
  }
  let dataUser=JSON.parse(localStorage.getItem(prefijoApp + '_dataUser'))
  store.commit('setTokenApp', token);
  store.commit('setDataUser', dataUser);

  let route=window.location.pathname;
  if (route==="/register" || route==="/login" || route==="/" || route==="/profile") {
    console.log('not redirect')
  } else {
    router.push('/');
    // console.log(router);
  }
//verificar si no es login/profile/register

  // Redirige al "home" cuando la página se carga
  //router.push('/'); // Ajusta el nombre de la ruta según tu configuración
});

</script>
