import { createStore } from 'vuex'
import axios from 'axios';
export default createStore({
  state: {
    nombreApp:'InventarioPe',
    idModulo:1,
    prefijoApp:'DYLG',
    urlApi:'https://inventariope.net.pe/back/public/api',
    // urlApi:'https://inventariope.dylgfacturafacil.com/back/public/api',
    // urlApi:'http://127.0.0.1:8000/api',
    tokenApp:'',
    dataUser:{},
    listComponents:[],
    tokenValido:false,
    modeApp:'production'
  },
  getters: {
    getIdModulo:state=>state.idModulo,
    getTokenApp:state=>state.tokenApp,
    getNombreApp:state=>state.nombreApp,
    getPrefijoApp:state=>state.prefijoApp,
    getDataUser:state=>state.dataUser,
    getUrlApi:state=>state.urlApi,
    getListComponents:state=>state.listComponents
  },
  mutations: {
    setTokenApp: (state, newToken) => {
      state.tokenApp = newToken;
    },
    setNombreApp: (state, newNombre) => {
      state.nombreApp = newNombre;
    },
    setPrefijoApp: (state, newPrefijo) => {
      state.prefijoApp = newPrefijo;
    },
    setDataUser: (state, newDataUser) => {
      state.dataUser = newDataUser;
    },
    // Otra mutación para actualizar el estado con los datos de la API
    setListComponents(state, newDataListComponents) {
      state.listComponents = newDataListComponents;
    },
  },
  actions: {
    checkLastTimeActivity() {
      const fechaHoraActual= new Date(); 
      let fechalstrg=localStorage.getItem(this.state.prefijoApp+'_fechaHoraSession');
      if (fechalstrg===null) {
        return -1;
      }
      const fechaUltimoIntento=new Date(JSON.parse(fechalstrg));
      const diferenciaMs = fechaHoraActual.getTime() - fechaUltimoIntento.getTime(); // Obtiene la diferencia en milisegundos
      const diferenciaMinutos = Math.floor(diferenciaMs / (1000 * 60)); // Convierte la diferencia a minutos
      return diferenciaMinutos; // Asigna la diferencia de minutos a la variable diferenciaMinutos en los datos del componente
    },
    setActivityApplication(){
      const fechaHoraActual= new Date();
      const fechaHoraString = JSON.stringify(fechaHoraActual); // Convierte la fecha y hora en una cadena de texto legible
      localStorage.setItem(this.state.prefijoApp+'_fechaHoraSession', fechaHoraString);
    },
  checkComponentAccess(context,{componentName}){
    if (context.state.modeApp==='development') {
      const prefijoApp = context.state.prefijoApp;
        const token = localStorage.getItem(prefijoApp + '_token');
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: context.state.urlApi + '/getcomponents/'+context.state.idModulo+'/'+componentName,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        };
        axios
          .request(config)
          .then((response) => {
            console.log(response)
          })
          .catch((error) => {
            console.log(error);
          });
    }else{
      console.log('Registrado:'+componentName)
    }
  }
  },
  modules: {
  }
})
