<script setup>
import { ref,onBeforeMount } from 'vue';
import { useStore } from 'vuex';

import AppMenuItem from './AppMenuItem.vue';
import axios from 'axios';

const store = useStore();
const model = ref();
onBeforeMount(() => {
        getDataBD();
        getDataComponents();
      });
// Function to get data from the database
const getDataBD = () => {
        const prefijoApp = store.getters.getPrefijoApp;
        const token = localStorage.getItem(prefijoApp + '_token');
        const dataUser=JSON.parse(localStorage.getItem(prefijoApp + '_dataUser'));
        const moduleId=store.getters.getIdModulo;
        // console.log(dataUser,token);
        
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: store.getters.getUrlApi + '/getmainsaccess/'+moduleId+'/'+dataUser.id,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        };
        axios
          .request(config)
          .then((response) => {
            model.value=response.data;
            model.value.unshift({
                label: 'Home',
                items: [{ label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' }]
            });
            // console.log(response.data,model.value)
          })
          .catch((error) => {
            console.log(error);
          });
      };
// Function to get data from the database
const getDataComponents = () => {
        const prefijoApp = store.getters.getPrefijoApp;
        const token = localStorage.getItem(prefijoApp + '_token');
        const dataUser=JSON.parse(localStorage.getItem(prefijoApp + '_dataUser'));
        const moduleId=store.getters.getIdModulo;
        // console.log(dataUser,token);
        
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: store.getters.getUrlApi + '/getusersaccesscomponents/'+moduleId+'/'+dataUser.id,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        };
        axios
          .request(config)
          .then((response) => {
            // console.log(response.data);
            store.commit('setListComponents', response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      };
</script>

<template>
    <ul class="layout-menu">
        <template v-for="(item, i) in model" :key="item">
            <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
            <li v-if="item.separator" class="menu-separator"></li>
        </template>
        <li>
            <a href="https://www.primefaces.org/primeblocks-vue/#/" target="_blank" >
                <img src="/layout/images/LOGO HORIZONTAL.png" alt="Prime Blocks" class="w-full mt-3" style="border-radius: 7px;" />
            </a>
        </li>
    </ul>
</template>

<style lang="scss" scoped></style>
