<script setup>
import { ref, computed, onMounted, onBeforeUnmount,onBeforeMount } from 'vue';
import { useLayout } from '@/layout/composables/layout';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import axios from 'axios';
const store = useStore();

//nombre de la aplicacion
const nombreApp = store.getters.getNombreApp
const { layoutConfig, onMenuToggle } = useLayout();

const outsideClickListener = ref(null);
const topbarMenuActive = ref(false);
const menuAvatar = ref();
const itemsAvatar = ref();
const router = useRouter();
const dataUser=ref([]);
const inicialesUser=ref();
const imageUser=ref();
const hasImage=ref(false);

onBeforeMount(() => {
    let prefijoApp = store.getters.getPrefijoApp;
        // let token = localStorage.getItem(prefijoApp + '_token');
        // let moduleId=store.getters.getIdModulo;
        dataUser.value=JSON.parse(localStorage.getItem(prefijoApp + '_dataUser'));
        imageUser.value=store.getters.getDataUser.image;
        
        if (imageUser.value!==null) {
            hasImage.value=true;
            console.log('se muestra las iniciales');
        }
        // console.log(imageUser.value);
        //OBTENIENDO iNCIALES
        inicialesUser.value = dataUser.value.name
        .split(" ") // Dividir la cadena en palabras usando el espacio como separador
        .map(word => word[0]) // Tomar la primera letra de cada palabra
        .join("");
        // console.log(inicialesUser.value)
        itemsAvatar.value=[
    {
        label: dataUser.value.email,
        items: [
            {
                label: 'Mi Perfil',
                icon: 'pi pi-user',
                command: () => {
                    router.push("/profile")
                }
            },
            {
                label: 'Cerrar Sesión',
                icon: 'pi pi-arrow-circle-right',
                command: () => {
                    logOut();
                    router.push("/login")
                }
            }
        ]
    }
];
        // console.log(dataUser.value) 
});
onMounted(() => {
    bindOutsideClickListener();
});

onBeforeUnmount(() => {
    unbindOutsideClickListener();
});

const logoUrl = computed(() => {
    return `layout/images/${layoutConfig.darkTheme.value ? 'logo-dark':'logo-white'}.png`;
    // return `layout/images/logo inventarios.png`;
});

const onTopBarMenuButton = () => {
    topbarMenuActive.value =true// !topbarMenuActive.value;
};
// const onSettingsClick = () => {
//     topbarMenuActive.value = false;
//     router.push('/documentation');
// };
const topbarMenuClasses = computed(() => {
    return {
        'layout-topbar-menu-mobile-active': topbarMenuActive.value
    };
});

const bindOutsideClickListener = () => {
    if (!outsideClickListener.value) {
        outsideClickListener.value = (event) => {
            if (isOutsideClicked(event)) {
                topbarMenuActive.value = false;
            }
        };
        document.addEventListener('click', outsideClickListener.value);
    }
};
const unbindOutsideClickListener = () => {
    if (outsideClickListener.value) {
        document.removeEventListener('click', outsideClickListener);
        outsideClickListener.value = null;
    }
};
const isOutsideClicked = (event) => {
    if (!topbarMenuActive.value) return;

    const sidebarEl = document.querySelector('.layout-topbar-menu');
    const topbarEl = document.querySelector('.layout-topbar-menu-button');

    return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
};



const toggle = (event) => {
    menuAvatar.value.toggle(event);
};
  // Function to get data from the database
  const logOut = () => {
  const prefijoApp = store.getters.getPrefijoApp;
  const token = localStorage.getItem(prefijoApp + '_token');
  localStorage.removeItem(prefijoApp + '_dataUser');
  localStorage.removeItem(prefijoApp + '_token');
  localStorage.removeItem(prefijoApp + '_fechaHoraSession');

  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: store.getters.getUrlApi + '/auth/logout',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  };

  axios
    .request(config)
    .then(() => {
      console.log('log out successfuly')
    })
    .catch((error) => {
      console.log(error);
    });
};

</script>

<template>
    <div class="layout-topbar ">
        <router-link to="/" class="layout-topbar-logo">
            <img :src="logoUrl" alt="logo" />
            <span>{{ nombreApp }}</span>
        </router-link>

        <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle()">
            <i class="pi pi-bars"></i>
        </button>

        <button class="p-link layout-topbar-menu-button layout-topbar-button" @click="onTopBarMenuButton()">
            <i class="pi pi-ellipsis-v"></i>
        </button>

        <div class="layout-topbar-menu" :class="topbarMenuClasses">
            <!-- <button @click="onTopBarMenuButton()" class="p-link layout-topbar-button">
                <i class="pi pi-bell"></i>
                <span>Calendar</span>
            </button> -->
            <Avatar  @click="toggle"
            :image="hasImage ? imageUser : null"
            :label="hasImage ? null : inicialesUser" class="p-link" size="large" style="background-color:#8C42A9; color: #ffffff;" shape="circle"  />
            
            <Menu ref="menuAvatar" id="overlay_menu" :model="itemsAvatar" :popup="true" />
        </div>
    </div>
</template>

