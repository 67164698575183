<template>
  <Toast />
  <div class="surface-card p-4 shadow-2 border-round w-full" style="margin: auto;" >
    <div class="text-900 text-3xl font-medium mb-3">Perfil de Usuario</div>
    <div class="text-center mb-5">
        <Image  :src="usersImage" class="my-2"  alt="Image" width="200" height="200" preview/>
        <FileUpload mode="basic" accept="image/*" customUpload @select="convertBase64" />
    </div>

    <div class="grid">
        <h5 class="col-12 text-center">{{ usersEmail }}</h5>
        <div class="col-12 md:col-4">
            <label for="DNI" class="block text-900 font-medium mb-2">DNI</label>
            <InputText id="DNI" v-model="usersDNI" disabled="" type="number" class="w-full mb-3" maxlength="8" />    
        </div>
        <div class="col-12 md:col-4">
            <label for="name" class="block text-900 font-medium mb-2">Nombres</label>
            <InputText id="name" v-model="usersName"  type="text" class="w-full mb-3" />       
        </div>
        <div class="col-12 md:col-4">
            <label for="last_name" class="block text-900 font-medium mb-2">Apellidos</label>
            <InputText id="last_name" v-model="usersLastName"  type="text" class="w-full mb-3" />       
        </div>
        <div class="col-12 md:col-6">
            <label for="passwordNew" class="block text-900 font-medium mb-2">Contraseña Nueva</label>
            <InputText id="passwordNew" v-model="usersPasswordNew"  type="password" class="w-full mb-3" />       
        </div>
        <div class="col-12 md:col-6">
            <label for="passwordNewConfirm" class="block text-900 font-medium mb-2">Confirme Contraseña Nueva</label>
            <InputText id="passwordNewConfirm" v-model="usersPasswordConfirm" type="password" class="w-full mb-3" />       
        </div>

        <Button label="Actualizar"  icon="pi pi-user" @click="saveBD" class="col-offset-4"></Button>
    </div>
</div>
</template>
<script setup>
  import { ref,onBeforeMount } from 'vue';
  import { useStore } from 'vuex';
  import axios from 'axios';
  import Toast from 'primevue/toast';
  import { useToast } from 'primevue/usetoast';

  const store = useStore();
  const usersId=ref(null);
  const usersDNI = ref(null);
  const usersName = ref(null);
  const usersLastName = ref(null);
  const usersEmail = ref(null);

  const usersPasswordNew = ref(null);
  const usersPasswordConfirm = ref(null);
  const usersImage = ref(null);
//funcion para el toast los mensajes a enviar
const toast = useToast();
const submitted = ref(false);
  
  onBeforeMount(() => {
    getDataBD();
    console.log(store.getters.getDataUser)
  });
  
  const showMsg = (severityMsg,summaryMsg,detailMsg,lifeMsg) => {
    toast.add({ severity: severityMsg, summary: summaryMsg, detail: detailMsg, life: lifeMsg });
  };
  // Function to get data from the database
const getDataBD = () => {
  const prefijoApp = store.getters.getPrefijoApp;
  let jsonDataUser=JSON.parse(localStorage.getItem(prefijoApp + '_dataUser'))
  usersId.value=jsonDataUser.id;
  const token = localStorage.getItem(prefijoApp + '_token');
  const config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: store.getters.getUrlApi + '/userget/'+jsonDataUser.id,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  };

  axios
    .request(config)
    .then((response) => {
      usersId.value=response.data.id;
      usersDNI.value=response.data.DNI;
      usersName.value=response.data.name;
      usersLastName.value=response.data.last_name;
      usersEmail.value=response.data.email;
      usersImage.value=response.data.image;
    })
    .catch((error) => {
      console.log(error);
    });
};
// Function to save the data to the backend
const saveBD = () => {
    submitted.value = true;
    let method = "";
    let urlForApi = "";
    const dataSendBD = {
        
        name:usersName.value,
        last_name:usersLastName.value,
        password:usersPasswordConfirm.value,
        image:usersImage.value
    };
    console.log(dataSendBD)
    if (usersPasswordNew.value!=usersPasswordConfirm.value) {
      showMsg('error','Confirme Contraseña','Las Contraseñas no son Iguales.',7000)
      return ;
    }
    // Verifying if it is a PUT or POST request
      method = "put";
      urlForApi = store.getters.getUrlApi + '/users/'+usersId.value;
    
      const token = localStorage.getItem(store.getters.getPrefijoApp + "_token");
      const data = JSON.stringify(dataSendBD);
      const config = {
        method: method,
        maxBodyLength: Infinity,
        url: urlForApi,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        data: data,
      };
  
      axios
        .request(config)
        .then((response) => {
          let prefijoApp=  store.getters.getPrefijoApp
                      
                      let dataUser=JSON.stringify(response.data.data);
                      const fechaHoraActual = new Date(); // Obtiene la fecha y hora actual
                      const fechaHoraString = JSON.stringify(fechaHoraActual); // Convierte la fecha y hora en una cadena de texto legible
                      localStorage.setItem(prefijoApp+'_fechaHoraSession', fechaHoraString); 
                      localStorage.setItem(prefijoApp+'_dataUser',dataUser);
                    store.commit('setTokenApp', token);
                    store.commit('setDataUser', response.data.data);
        })
        .catch((error) => {
          console.log(error.errors);
        });
    
  };
  //c¿funtion to conver base 64
  const convertBase64 = async (event) => {
    const file = event.files[0];
    const reader = new FileReader();
    let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url

    reader.readAsDataURL(blob);

    reader.onloadend = function () {
      usersImage.value = reader.result;
    };
    
}; 
  </script>
  <style >
    .p-image-preview-container > img {
        border-radius: 100px;
    }
    .p-image-preview-indicator {
      border-radius: 100px;
    }
  </style>