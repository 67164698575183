<script setup>
import { ref,onBeforeMount } from 'vue';
import Chart from 'primevue/chart';
import axios from 'axios';
import { useStore } from 'vuex';

const store = useStore();
const dataDash = ref(null);
const dataUser=ref(null);
const proyects=ref([]);
const bienes=ref(0);
const inventariados=ref(0);
const idInstitution=ref(0);
const sobrantes=ref(0);
const chartData = ref();
const chartOptions = ref();

const getDataDashboard = () => {
    let urlconfig='';
    if (idInstitution.value>0) {
        urlconfig='/'+ idInstitution.value;
    }
  let prefijoApp = store.getters.getPrefijoApp;
  let token = localStorage.getItem(prefijoApp + '_token');
  dataUser.value=JSON.parse(localStorage.getItem(prefijoApp + '_dataUser'));
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: store.getters.getUrlApi + '/dashboard/' + dataUser.value.id+urlconfig,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
    },
  };
  // console.log(config.url);
  axios
    .request(config)
    .then((response) => {
      
      dataDash.value = response.data;
      proyects.value= dataDash.value[0].proyects;
      bienes.value=dataDash.value[0].bienes;
      inventariados.value=dataDash.value[0].bienesInventariados;
      sobrantes.value=dataDash.value[0].bienesSobrantes;
      changeDataForChart(dataDash.value[0].recordByUser);
    //   console.log(response.data)
    })
    .catch((error) => {
      console.log(error);
    });
};
onBeforeMount(() => {
    getDataDashboard();
});


const changeDataForChart=(data)=>{
    const labels = data.map(item => item.name);
    const values = data.map(item => item.cantidad_registros);
    chartData.value = setChartData(labels,values);
    chartOptions.value = setChartOptions();
    // console.log(names);
}

const setChartData = (labels,values) => {
    const documentStyle = getComputedStyle(document.documentElement);

    return {
        labels: labels,
        datasets: [
            {
                label: 'Avances de los Usuarios',
                backgroundColor: documentStyle.getPropertyValue('--cyan-500'),
                borderColor: documentStyle.getPropertyValue('--cyan-500'),
                data: values
            }
        ]
    };
};
const setChartOptions = () => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    return {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                labels: {
                    color: textColor
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary,
                    font: {
                        weight: 500
                    }
                },
                grid: {
                    display: false,
                    drawBorder: false
                }
            },
            y: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder,
                    drawBorder: false
                }
            }
        }
    };
}

</script>

<template>
    <div class="grid">
        <div class="col-12 mt-2 doc-intro">
            <h4 style="text-align: center;">SISTEMA DE GESTIÓN DE INVENTARIOS</h4>
        </div>
        <div class="col-12 xl:col-6">
            <div class="card p-2">
                <h5>Proyectos</h5>
                <DataTable :value="proyects"  >
                    <Column style="width: 80%">
                        <template #header> PROYECTOS </template>
                        <template #body="slotProps">
                            <div class="flex align-items-center gap-2">
                                <img :src="slotProps.data.logo" style="width: 32px" />
                                <span>{{ slotProps.data.name }}</span>
                            </div>
                        </template>
                    </Column>
                    
                    <Column header="Ver" style="width: 20%">
                        <template #body="slotProps">
                            <Button icon="pi pi-search" type="button" class="p-button-text" @click="idInstitution=slotProps.data.id;getDataDashboard ()"/>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <div class="col-12 lg:col-6 xl:col-2">
            <div class="card mb-0">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class="block text-500 font-medium mb-3">Total de bienes</span>
                        <div class="text-900 font-medium text-xl">{{ bienes }}</div>
                    </div>
                    <div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width: 2.5rem; height: 2.5rem">
                        <i class="pi pi-map-marker text-orange-500 text-xl"></i>
                    </div>
                </div>
                <span class="text-green-500 font-medium">Registro Inicial</span>
                <!--<span class="text-500">since last week</span> -->
            </div>
        </div>
        <div class="col-12 lg:col-6 xl:col-2">
            <div class="card mb-0">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class="block text-500 font-medium mb-3">Inventariados</span>
                        <div class="text-900 font-medium text-xl">{{ inventariados }}</div>
                    </div>
                    <div class="flex align-items-center justify-content-center bg-cyan-100 border-round" style="width: 2.5rem; height: 2.5rem">
                        <i class="pi pi-inbox text-cyan-500 text-xl"></i>
                    </div>
                </div>
                <span class="text-red-500 font-medium">{{sobrantes}} Sobrantes </span>
                <span class="text-green-500">Total {{ sobrantes+inventariados }} </span>
            </div>
        </div>
        <div class="col-12 lg:col-6 xl:col-2">
            <div class="card mb-0">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class="block text-500 font-medium mb-3">Faltantes</span>
                        <div class="text-900 font-medium text-xl">{{ bienes-inventariados }}</div>
                    </div>
                    <div class="flex align-items-center justify-content-center bg-purple-100 border-round" style="width: 2.5rem; height: 2.5rem">
                        <i class="pi pi-comment text-purple-500 text-xl"></i>
                    </div>
                </div>
                <span class="text-green-500 font-medium">Bienes Faltantes</span>
                <!-- <span class="text-500">responded</span> -->
            </div>
        </div>
        
        <div class="col-12 mt-2">
            <div class="card">
                <Chart type="bar" :data="chartData" :options="chartOptions" class="h-30rem"  />
            </div>
        </div>
    </div>
</template>
